import { useTranslation } from "react-i18next";
import { storage } from "../../service";
import styles from "./pdf-header.module.css";
import { format } from "date-fns";
export const PdfHeaderToD10 = ({ title, children, order }) => {
  const today = new Date(Date.now()).toLocaleDateString();
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const ordersDate = new Date(Date(order?.ordered_date));
  const year = ordersDate.toLocaleString("default", { year: "numeric" });
  const month = ordersDate.toLocaleString("default", { month: "2-digit" });
  const day = ordersDate.toLocaleString("default", { day: "2-digit" });
  const formattedDate = day + "." + month + "." + year;
  const role = storage.read("role");
  const district = storage.read("district");
  console.log(language);
  return (
    <>
      {role === "librarian" && (
        <div
          className={styles.title + " " + styles.print}
          style={{ margin: "3rem" }}
        >
          <br />
          <br />
          <h3 style={{ marginTop: "3rem" }}>Отчет Д10</h3>
          {role === "librarian" && language === "ru" && (
            <span>
              школы {storage.read("school")} (код-{storage.read("school_id")})
            </span>
          )}
          {role === "librarian" && language === "ky" && (
            <span>
              {storage.read("school")} мектеби ({storage.read("school_id")} -
              коду)
            </span>
          )}
          <br />
          <span>{formattedDate} </span>
          <br />
        </div>
      )}
      {role === "dist_observer" && (
        <div
          className={styles.title + " " + styles.print}
          style={{ marginTop: "7rem", fontSize: "16px"}}
        >
          <br />
          <br />
          <br />
          <br />

          {language === "ru" && (
            <span
              style={{
                display: "flex",
                justifyContent: "end",
                textAlign: "end",
                padding: "0 50px 0 0",
              }}
            >
              Директору ГУ ИД <br /> «Окуу китеби» при МОН КР <br /> Сарыбаеву
              И.А. <br /> от <br /> <br /> _________________ <br />
            </span>
          )}
          {language === "ky" && (
            <span
              style={{
                display: "flex",
                justifyContent: "end",
                textAlign: "end",
                padding: "0 50px 0 0",
              }}
            >
              Кыргыз Республикасынын Билим <br /> берүү жана илим министрлигине{" "}
              <br />
              караштуу «Окуу китеби» басма <br /> үйү мамлекеттик мекемесинин
              <br />
              директору Сарыбаев И.А.
              <br />
              <br />
              _________________
            </span>
          )}
          <br />
          <span
            style={{
              display: "flex",
              justifyContent: "end",
              textAlign: "end",
              padding: "100px 50px 0 0",
            }}
          >
            {formattedDate}{" "}
          </span>
          <br />
        </div>
      )}
    </>
  );
};
