import {Modal, Form, Col, Row} from "react-bootstrap"
import {useState} from "react"
import {notifyError, notifySuccess} from "../../components/notify";
import {useTranslation} from "react-i18next";
import {gradeEnum, langOptions, transLang} from "../../lib/helper";
import {ButtonC, InputField, SelectField} from "../../components";
import {useForm} from "react-hook-form";
import {useActions, useAppSelector} from '../../hooks';
import {thunks} from './class-reducer';
import {thunks as gradeThunks} from '../../app/store/grade-reducer';
import Select from "react-select";

const actions = {
  updateClass: thunks.updateClass,
  fetchGrades: gradeThunks.fetchGrades
}

export const UpdateClass = ({show, handleClose, editValue, setShow}) => {
  const teachers = useAppSelector((state) => state.teachers.teachers)
  const letter = useAppSelector((state) => state.letters.letters)
  const {updateClass, fetchGrades} = useActions(actions)
  const [active, setActive] = useState(true)
  const [currentTeacher, setCurrentTeacher] = useState()
  const {control, formState: {errors}, handleSubmit, reset, setValue} = useForm()
  const {t} = useTranslation()
  console.log(editValue)

  const changeTeacher = (selectedOption) => {
    const teacher = teachers.find((t) => t.id == selectedOption?.value);
      if (teacher) {
        setActive(true)
        setCurrentTeacher(teacher)
        setValue('email', teacher.email || '')
        setValue('pin', teacher.pin || '')
        setValue('phone', teacher.phone || '')
      } else {
        setActive(false)
        setCurrentTeacher({})
        setValue('email', '')
        setValue('pin', '')
        setValue('phone', '')
      }
  }

  const defaultLetter = () => {
    const l = editValue?.name?.slice(parseInt(editValue?.name) > 9 ? 2 : 1)
    for (let i = 0; i < letter?.length; i++) {
      if (letter[i].name == l) return letter[i].id
    }
  }

  const putKlass = async (data) => {
    if (!active) {
      notifyError('Выберите учителя из списка')
      return
    }
    const klass = {
      teacher: currentTeacher?.id ?? editValue?.teacher?.id,
      student_count: data.student_count,
      student_count_50_discount: data.student_count_50_discount,
      student_count_100_discount: data.student_count_100_discount,
      grade: parseInt(data.grade),
      letter: data.letter,
      language: data.language
    }

    const a = await updateClass({id: editValue.id, data: klass})
    if (a.payload) {
      reset()
      notifySuccess('Изменено!')
      setShow(false)
      fetchGrades()
    }

  }
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        keyboard={false}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>{t(`classes.update-class.title`, {value: editValue?.name})}</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Form onSubmit={handleSubmit(putKlass)}>
            <Col xs={12}>
            <label>{t('classes.add-class.fullname')}</label>
            <Select
              options={teachers.map((t) => ({ label: t.fullname, value: t.id }))}
              onChange={changeTeacher}
              placeholder={t('classes.add-class.fullname')}
              isClearable
              defaultValue={
                teachers
                  .filter((t) => t.id === editValue?.teacher?.id)
                  .map((t) => ({ label: t.fullname, value: t.id }))[0]
              }
              noOptionsMessage={() => "Учитель не найден"}
            />
            </Col>
            <Col xs={12}>
              <InputField
                type="email"
                control={control}
                name='email'
                disabled={true}
                defaultValue={editValue.teacher?.email}
                label='E-mail'
              />
            </Col>
            <Row md={2} xs={1}>
              <Col>
                <InputField
                  type="number"
                  control={control}
                  name='pin'
                  disabled={true}
                  defaultValue={editValue?.teacher?.pin}
                  label={t('classes.add-class.pin')}
                />
              </Col>
              <Col>
                <InputField
                  type="tel"
                  control={control}
                  name='phone'
                  disabled={true}
                  defaultValue={editValue?.teacher?.phone}
                  label={t('classes.add-class.phone')}
                />
              </Col>
            </Row>
            <Row xs={1} lg={1}>
              <Col>
                <InputField
                  control={control}
                  name='student_count'
                  rules={{required: true}}
                  defaultValue={editValue?.student_count}
                  type='number'
                  label={t('classes.add-class.student_count')}
                  errors={errors.student_count}
                />
              </Col>
              <Col>
                <InputField
                  control={control}
                  name='student_count_50_discount'
                  defaultValue={editValue?.student_count_50_discount}
                  type='number'
                  label={t('classes.add-class.student_with_50_percent')}
                  errors={errors.student_count_50_discount}
                />
              </Col>
              {/* {console.log('ed', editValue.student_count_100_discount)} */}
              <Col>
                <InputField
                  control={control}
                  name='student_count_100_discount'
                  defaultValue={editValue?.student_count_100_discount}
                  type='number'
                  label={t('classes.add-class.student_with_100_percent')}
                  errors={errors.student_count_100_discount}
                />
              </Col>
              <Col>
              <SelectField
                  control={control}
                  name='grade'
                  required
                  defaultValue={editValue?.grade}
                  options={gradeEnum.map((g) => ({value: g.id, label: g}))}
                  errors={errors.letter}
                  label={t('classes.add-class.number')}
                />
                </Col>
              <Col>
                <SelectField
                  control={control}
                  name='letter'
                  rules={{required: t('error_message.no_empty')}}
                  required
                  optGrpHead={t('classes.update-class.сhoose_letter')}
                  defaultValue={defaultLetter()}
                  options={letter.map((l) => ({value: l.id, label: l.name}))}
                  errors={errors.letter}
                  label={t('classes.add-class.letter')}
                />
              </Col>
              <Col>
                <SelectField
                  control={control}
                  name="language"
                  rules={{required: t('error_message.no_empty')}}
                  required
                  defaultValue={transLang(editValue?.language)}
                  options={langOptions}
                  errors={errors.language}
                  label={t('classes.add-class.language')}
                  // translated={true}
                />
              </Col>
            </Row>
            <Col className='moduleBtns'>
              <ButtonC variant="close" onClick={() => {
                handleClose()
                reset()
              }}>
                {t('btn.close')}
              </ButtonC>
              <ButtonC variant="add" type='submit'>
                {t('btn.change')}
              </ButtonC>
            </Col>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  )
}