import { useTranslation } from "react-i18next";
import { storage } from "../../service";
import styles from "./pdf-header.module.css";
import { format } from "date-fns";
import { useAppSelector } from "../../hooks";

export const PdfFooterToD10 = ({ title, children, order }) => {
  const today = new Date(Date.now()).toLocaleDateString();
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  console.log(language);

  const user = useAppSelector((state) => state.profile.user);
  const role = storage.read("role");
  const userStorage = storage.read("employee");

  return (
    <>
      {role === "librarian" && (
        <div className={styles.print}>
          <br />
          {language === "ru" && (
            <p>Подтверждаем правильность внесенных данных</p>
          )}
          {language === "ky" && (
            <p>Киргизилген маалыматтардын тууралыгын тастыктайбыз</p>
          )}
          <br />
          <p>
            {t("print.director2")} ____________________{" "}
            {storage.read("director")}
          </p>
          <br />
          <p>
            {t("print.librarian")} _______________ {user?.fullname}
          </p>
          <br />
          {language === "ru" && <p>М.П.</p>}
          {language === "ky" && <p>М.О.</p>}
        </div>
      )}

      {role === "dist_observer" && (
        <div className={styles.print}>
          {language === "ky" && (
            <h3 style={{ marginTop: "4rem", fontSize: "24px" }}>
              Д10 отчетун тастыктоо
            </h3>
          )}
          {language === "ru" && (
            <h3 style={{ marginTop: "4rem", fontSize: "24px" }}>
              Подтверждение отчета Д10
            </h3>
          )}
          <br />
          {language === "ru" && (
            <p style={{ padding: "0 60px", textIndent: "20px" }}>
              Я, {userStorage}, настоящим подтверждаю, что данные, указанные в
              предоставленных всеобщих отчетах Д10, являются достоверными и
              соответствуют действительности.
            </p>
          )}
          {language === "ky" && (
            <p style={{ padding: "0 60px", textIndent: "20px" }}>
              Мен, {userStorage}, берилген отчеттордо көрсөтүлгөн жалпыланган
              Д10 отчетунун так жана чындыкка дал келгендигин тастыктайм.
            </p>
          )}
          <br />
          <p style={{ margin: "300px 0 0 0", padding: "0 0 0 60px" }}>
            {userStorage} ____________________
          </p>
          <br />
          {language === "ru" && (
            <p style={{ marginBottom: "280px", padding: "0 0 0 60px" }}>М.П.</p>
          )}
          {language === "ky" && (
            <p style={{ marginBottom: "280px", padding: "0 0 0 60px" }}>М.О.</p>
          )}
        </div>
      )}
    </>
  );
};
