import React from "react";
import { useTranslation } from "react-i18next";
import { transLangFromEng } from "../../../lib/helper";

export const D10TRow = ({
  rowSpan,
  subjectIndex,
  bookIndex,
  booksLength,
  grade,
  book,
  subjectName,
}) => {
  const { t } = useTranslation();

  if (subjectIndex === 0 && bookIndex === 0) {
    return (
      <tr
        key={book.book_id}
        style={{
          backgroundColor: booksLength === 1 ? "rgba(43, 191, 61, 0.2)" : "",
        }}
      >
        <td data-label="Классы" rowSpan={rowSpan} className="grade">
          {grade.grade}
        </td>
        <td data-label="Всего учащихся" rowSpan={rowSpan} className="grade">
          {grade.number_of_students}
        </td>
        <td data-label="Автор и наименование учебника">
          {booksLength === 1 ? (
            <>
              <i style={{ fontSize: "80%" }}>
                итого по предмету ({subjectName}):
              </i>{" "}
              <br />{" "}
            </>
          ) : (
            ""
          )}{" "}
          {book.book__name}{" "}
          {book?.book_number_of_parts > 1 ? (
            <>
              (1-{book?.book_number_of_parts} {t("school-books.part")})
            </>
          ) : (
            ""
          )}{" "}
          - {book.book__author}
        </td>
        <td data-label="Язык">{transLangFromEng(book.book_language)}</td>
        {/* {book.books_in_regions.length &&
                    book.books_in_regions.map((region) => (
                        <td data-label="asd" key={region.id}>{region.total_books}</td>
                    ))} */}
        <td data-label="Учебников в фонде">{book.total_number_of_books}</td>
        <td data-label="% обеспеченности по фонду">
          {book.fund_security_percentage.toFixed(2)}
        </td>
        <td data-label="Используются">{book.number_of_issued_books}</td>
        <td data-label="% использование фонда">
          {book.fund_utilization_percentage.toFixed(2)}
        </td>
      </tr>
    );
  } else {
    return (
      <tr
        style={{
          backgroundColor: booksLength === 1 ? "rgba(43, 191, 61, 0.2)" : "",
        }}
      >
        <td data-label="Автор и наименование учебника">
          {booksLength === 1 ? (
            <>
              <i style={{ fontSize: "80%" }}>
                итого по предмету ({subjectName}):
              </i>{" "}
              <br />{" "}
            </>
          ) : (
            ""
          )}{" "}
          {book.book__name}{" "}
          {book?.book_number_of_parts > 1 ? (
            <>
              (1-{book?.book_number_of_parts} {t("school-books.part")})
            </>
          ) : (
            ""
          )}{" "}
          - {book.book__author}
        </td>
        <td data-label="Язык">{transLangFromEng(book.book_language)}</td>
        {/* {book.books_in_regions.length &&
                    book.books_in_regions.map((region) => (
                        <td data-label={region.id} key={region.id}>{region.total_books}</td>
                    ))} */}
        <td data-label="Учебников в фонде">{book.total_number_of_books}</td>
        <td data-label="% обеспеченности по фонду">
          {book.fund_security_percentage.toFixed(2)}
        </td>
        <td data-label="Используются">{book.number_of_issued_books}</td>
        <td data-label="% использование фонда">
          {book.fund_utilization_percentage.toFixed(2)}
        </td>
      </tr>
    );
  }
};
