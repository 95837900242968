import React, { useRef } from "react";
import { storage } from "../../../service";
import { ButtonC, TableComponent } from "../../../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";
import { PdfHeaderToD10 } from "../../../components/pdf-header/pdf-header-to-d10";
import { PdfFooterToD10 } from "../../../components/pdf-header/pdf-footer-to-d10";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";

const D10ConfirmPdf = ({ language }) => {
  const componentRef = useRef(null);
  const { t } = useTranslation();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const role = storage.read("role");
  return (
    <div className="d-flex flex-column">
      <ButtonC
        className="no-print mt-3"
        variant="download"
        onClick={handlePrint}
        style={{
          width: "max-content",
          float: "left",
          marginBottom: "15px",
          backgroundColor: "red",
          color: "#fff",
        }}
      >
        {t("report.button")}{" "}
        <FontAwesomeIcon style={{ color: "#fff" }} icon={faFilePdf} />
      </ButtonC>
      <TableComponent id="pdftable" responsive={true} ref={componentRef}>
        <PdfHeaderToD10 language={language} />
        <div id="pdfdiv"></div>
        <PdfFooterToD10 language={language} />
      </TableComponent>
    </div>
  );
};
// role === "dist_observer"
export default D10ConfirmPdf;
