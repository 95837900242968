import React from 'react'
import { D10TRow } from './D10TRow';
import {getRowSpan} from '../../../service/utils';

export const D10TBody = ({report}) => {
    const rows = [];
    const gradesData = report?.grades_data;
    const summaries = report?.summaries;
    let summary = null;
    gradesData?.map((gradeData) => {
        const rowSpan = getRowSpan(gradeData);
        gradeData.subjects.map((subject, subjectIndex) => {
            const booksLength = subject.books.length;
            subject.books.map((book, bookIndex) => {
                rows.push(
                   <>
                    <D10TRow
                    rowSpan={rowSpan}
                    subjectIndex={subjectIndex}
                    bookIndex={bookIndex}
                    booksLength={booksLength}
                    grade={gradeData}
                    book={book}
                    subjectName={subject.subject_name}
                    />
                   </>)
            })
            if (subject.subject_summary) {
                const subject_summary = subject.subject_summary;
                rows.push(
                    <tr  style={{backgroundColor: '#00D0EA', color: 'black'}}>
                        <td>Итоги по предмету ({subject?.subject_name})</td>
                        <td></td>
                        <td data-label="Учебников в фонде">{subject_summary?.total_number_of_books}</td>
                        <td data-label="% обеспеченности по фонду">{subject_summary?.fund_security_percentage.toFixed(2)}</td>
                        <td data-label="Используются">{subject_summary?.number_of_issued_books}</td>
                        <td data-label="% использование фонда">{subject_summary?.fund_utilization_percentage.toFixed(2)}</td>
                    </tr>
                )
            }
        })
        if (gradeData.grade_summary) {
          const grade_summary = gradeData.grade_summary;
          rows.push(
              <tr style={{backgroundColor: '#00A5AC', color: 'black'}}>
                  <td  colSpan={3} className="grade-result">Итоги {gradeData?.grade} класса</td>
                  <td></td>
                  <td data-label="Учебников в фонде">{grade_summary?.total_number_of_books}</td>
                  <td data-label="% обеспеченности по фонду">{grade_summary?.fund_security_percentage.toFixed(2)}</td>
                  <td data-label="Используются">{grade_summary?.number_of_issued_books}</td>
                  <td data-label="% использование фонда">{grade_summary?.fund_utilization_percentage.toFixed(2)}</td>
              </tr>
          )
      }
      })

      summary = summaries?.elementary_school;
      rows.push(
        <tr style={{backgroundColor: '#fefff0', color: 'black'}}>
            <td data-label="Классы">1-4 классы</td>
            <td data-label="Всего учащихся">{summary?.number_of_students}</td>
            <td data-label="Классы">1-4 классы</td>
            <td></td>
            <td data-label="Учебников в фонде">{summary?.total_number_of_books}</td>
            <td data-label="% обеспеченности по фонду">{summary?.fund_security_percentage.toFixed(2)}</td>
            <td data-label="Используются">{summary?.number_of_issued_books}</td>
            <td data-label="% использование фонда">{summary?.fund_utilization_percentage.toFixed(2)}</td>
        </tr>
      )

      summary = summaries?.junior_high_school;
      rows.push(
        <tr style={{backgroundColor: '#adffd8', color: 'black'}}>
            <td data-label="Классы">5-9 классы</td>
            <td data-label="Всего учащихся">{summary?.number_of_students}</td>
            <td data-label="Классы">5-9 классы</td>
            <td></td>
            <td data-label="Учебников в фонде">{summary?.total_number_of_books}</td>
            <td data-label="% обеспеченности по фонду">{summary?.fund_security_percentage.toFixed(2)}</td>
            <td data-label="Используются">{summary?.number_of_issued_books}</td>
            <td data-label="% использование фонда">{summary?.fund_utilization_percentage.toFixed(2)}</td>
        </tr>
      )

      summary = summaries?.high_school;
      rows.push(
        <tr style={{backgroundColor: '#b8b3fe', color: 'black'}}>
            <td data-label="Классы">10-11 классы</td>
            <td data-label="Всего учащихся">{summary?.number_of_students}</td>
            <td data-label="Классы">10-11 классы</td>
            <td></td>
            <td data-label="Учебников в фонде">{summary?.total_number_of_books}</td>
            <td data-label="% обеспеченности по фонду">{summary?.fund_security_percentage.toFixed(2)}</td>
            <td data-label="Используются">{summary?.number_of_issued_books}</td>
            <td data-label="% использование фонда">{summary?.fund_utilization_percentage.toFixed(2)}</td>
        </tr>
      )

      summary = summaries?.overall_summary;
      rows.push(
        <tr style={{backgroundColor: '#addcff', color: 'black'}}>
            <td data-label="Классы">1-11 классы</td>
            <td data-label="Всего учащихся">{summary?.number_of_students}</td>
            <td data-label="Классы">1-11 классы</td>
            <td></td>
            <td data-label="Учебников в фонде">{summary?.total_number_of_books}</td>
            <td data-label="% обеспеченности по фонду">{summary?.fund_security_percentage.toFixed(2)}</td>
            <td data-label="Используются">{summary?.number_of_issued_books}</td>
            <td data-label="% использование фонда">{summary?.fund_utilization_percentage.toFixed(2)}</td>
        </tr>
      )
  return (
    <tbody>{rows}</tbody>
  )
}
