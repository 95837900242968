import { faFileCsv, faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { isEdu_min, isLibrarian, transLangFromEng } from "../../../lib/helper";
import { storage } from "../../../service";
import { api } from "../../../service/old-api-2";
import { D10DownloadFile } from "../d10-download-file";
import { D10Filter } from "./D10Filter";
import { D10Table } from "./D10Table";
import "./styles.css";
import {
  isDistObserver,
  isJanyKitep,
  isLabrarianOrDirector,
  isRegObserver,
} from "../../../lib/helper";
import styles from "./../report.module.css";
import { read } from "../../../service/storage";
import {
  notifyError,
  notifySuccess,
  notifyInfo,
} from "../../../components/notify";
import { TableComponent } from "../../../components";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import D10ConfirmPdf from "./D10ConfirmPdf";

export const D10 = ({ initQuery, forDistrict = false }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const isReportResultPage = location.pathname.startsWith("/report-result/");
  const [report, setReport] = useState();
  const [language, setLanguage] = useState();
  const [recommended, setRecommended] = useState();
  const [d10Confirm, setD10Confirm] = useState(false);
  const [formRu, setFormRu] = useState(false);
  const [formKg, setFormKg] = useState(false);
  const [formTj, setFormTj] = useState(false);
  const [formUz, setFormUz] = useState(false);
  const [formFile, setFormFile] = useState(false);
  const [formSubmit, setFormSubmit] = useState(true);
  const [d10List, setD10List] = useState();

  const get_actual_d10_confirm = () => {
    api.get("actual-d10-confirm").then((response) => {
      // console.log(response);
      if (response !== false) {
        setD10Confirm(response);
        get_d10_list();
      }
    });
  };
  const get_d10_list = () => {
    api.get("school-d10-all").then((response) => {
      console.log(response);
      if (response) {
        setD10List(response);
        // console.log(response);
      }
    });
  };
  const fetchReport = (query) => {
    const newQuery = { ...initQuery, ...query };
    api.get("reports/d10/", { search: newQuery }).then((response) => {
      setReport(response);
      setLanguage(query.language);
      setRecommended(query.is_recommended);
    });
  };
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const learningLanguage = language;
  useEffect(() => {
    fetchReport(initQuery);
    get_actual_d10_confirm();
    setLanguage(initQuery.language);
  }, []);
  const date = new Date();
  const year = date.toLocaleString("default", { year: "numeric" });
  const month = date.toLocaleString("default", { month: "2-digit" });
  const day = date.toLocaleString("default", { day: "2-digit" });
  const formattedDate = year + "-" + month + "-" + day;
  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [selectedFile3, setSelectedFile3] = useState(null);
  const [selectedFile4, setSelectedFile4] = useState(null);
  const [selectedFile5, setSelectedFile5] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const selectedFileHandler1 = (event) => {
    setSelectedFile1(event.target.files[0]);
  };
  const selectedFileHandler2 = (event) => {
    setSelectedFile2(event.target.files[0]);
  };
  const selectedFileHandler3 = (event) => {
    setSelectedFile3(event.target.files[0]);
  };
  const selectedFileHandler4 = (event) => {
    setSelectedFile4(event.target.files[0]);
  };
  const selectedFileHandler5 = (event) => {
    setSelectedFile5(event.target.files[0]);
  };

  const selectedFileUploadHandler = () => {
    const access = read("access");
    if (selectedFile1 || selectedFile2 || selectedFile3 || selectedFile4) {
      const fd = new FormData();
      selectedFile1 ? fd.append("doc1", selectedFile1, selectedFile1.name) : null;
      selectedFile2 ? fd.append("doc2", selectedFile2, selectedFile2.name) : null;
      selectedFile3 ? fd.append("doc3", selectedFile3, selectedFile3.name) : null;
      selectedFile4 ? fd.append("doc4", selectedFile4, selectedFile4.name) : null;
      selectedFile5 ? fd.append("doc5", selectedFile5, selectedFile5.name) : null;

      setIsLoading(true);

      const uri = `${process.env.REACT_APP_BASE_URL}/api/d10-confirm/`;
      fetch(uri, {
        method: "POST",
        body: fd,
        headers: {
          Authorization: `Bearer ${access}`,
        },
      })
        .then((response) => {
          if (response.status === 201) {
            get_d10_list();
            setD10Confirm(!d10Confirm);
            notifySuccess("Документ успешно загружен");
            setIsLoading(false);
            //     return response.json().then((data) => {
            //         // setOrder(data)
            //         setBalanceConfirm(data);
            //         notifySuccess("Документ успешно загружен и передан в Жаны китеп")
            //     })
          } else if (response.status === 400 || response.status === 500) {
            response.json().then((errorData) => {
              const errorMessage = errorData.message;
              notifyError(errorMessage);
              setIsLoading(false);
            });
          }
        })
        .catch(() => {
          setIsLoading(false);
          notifyError("Произошла ошибка при загрузке.");
        });
    } else {
      notifyError("Документ не прикреплен.");
    }
  };

  const hideInputKg = () => {
    setFormKg(!formKg);
    setSelectedFile1(null);
  };
  const hideInputRu = () => {
    setFormRu(!formRu);
    setSelectedFile2(null);
  };
  const hideInputTj = () => {
    setFormTj(!formTj);
    setSelectedFile3(null);
  };
  const hideInputUz = () => {
    setFormUz(!formUz);
    setSelectedFile4(null);
  };
  const checkSubmit = () => {
    if (isReportResultPage) {
      if (
        (selectedFile1 || formKg) &&
        (selectedFile2 || formRu) &&
        (selectedFile3 || formTj) &&
        (selectedFile4 || formUz) &&
        (selectedFile5 || formFile)
      ) {
        if (formKg && formRu && formTj && formUz && formFile) {
          setFormSubmit(true);
        } else {
          setFormSubmit(false);
        }
      } else {
        setFormSubmit(true);
      }
    } else {
      if (
        (selectedFile1 || formKg) &&
        (selectedFile2 || formRu) &&
        (selectedFile3 || formTj) &&
        (selectedFile4 || formUz)
      ) {
        if (formKg && formRu && formTj && formUz) {
          setFormSubmit(true);
        } else {
          setFormSubmit(false);
        }
      } else {
        setFormSubmit(true);
      }
    }
  };
  useEffect(() => {
    checkSubmit();
  }, [
    formKg,
    formRu,
    formTj,
    formUz,
    formFile,
    selectedFile1,
    selectedFile2,
    selectedFile3,
    selectedFile4,
    selectedFile5,
  ]);

  console.log(language);

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <D10Filter onChange={fetchReport} />
        <div className="py-4 px-5 learning-language">
          {" "}
          Язык обучения:{" "}
          <div className="language">
            {transLangFromEng(language !== "" ? language : "all")}
          </div>{" "}
        </div>
        {/* {(isLabrarianOrDirector || isDistObserver || isRegObserver) && <D10DownloadFile
          url={`reports/d10/`}
          query={{ ...initQuery, language: language }}
        />} */}
        <D10DownloadFile
          url={`reports/d10/`}
          query={{
            ...initQuery,
            language: language,
            is_recommended: recommended,
          }}
        />
        <div>
          {isJanyKitep && (
            <a
              style={{ height: "max-content", alignItems: "center" }}
              className={"btn btn-warning " + styles.download}
              href={
                process.env.REACT_APP_BASE_URL +
                "/media-api/d10_reports/" +
                formattedDate +
                "_for_kyrgyz.xlsx"
              }
            >
              <FontAwesomeIcon
                style={{ color: "green", marginRight: "10px" }}
                icon={faFileExcel}
              />{" "}
              Скачать Excel кыргызского языка
            </a>
          )}
          {isJanyKitep && (
            <a
              style={{ height: "max-content", alignItems: "center" }}
              className={"btn btn-warning " + styles.download}
              href={
                process.env.REACT_APP_BASE_URL +
                "/media-api/d10_reports/" +
                formattedDate +
                "_for_russian.xlsx"
              }
            >
              <FontAwesomeIcon
                style={{ color: "green", marginRight: "10px" }}
                icon={faFileExcel}
              />{" "}
              Скачать Excel русского языка
            </a>
          )}
          {isJanyKitep && (
            <a
              style={{ height: "max-content", alignItems: "center" }}
              className={"btn btn-warning " + styles.download}
              href={
                process.env.REACT_APP_BASE_URL +
                "/media-api/d10_reports/" +
                formattedDate +
                "_for_tajik.xlsx"
              }
            >
              <FontAwesomeIcon
                style={{ color: "green", marginRight: "10px" }}
                icon={faFileExcel}
              />{" "}
              Скачать Excel таджикского языка
            </a>
          )}
          {isJanyKitep && (
            <a
              style={{ height: "max-content", alignItems: "center" }}
              className={"btn btn-warning " + styles.download}
              href={
                process.env.REACT_APP_BASE_URL +
                "/media-api/d10_reports/" +
                formattedDate +
                "_for_uzbek.xlsx"
              }
            >
              <FontAwesomeIcon
                style={{ color: "green", marginRight: "10px" }}
                icon={faFileExcel}
              />{" "}
              Скачать Excel узбекого языка
            </a>
          )}

          {isEdu_min && (
            <a
              style={{ height: "max-content", alignItems: "center" }}
              className={"btn btn-warning " + styles.download}
              href={
                process.env.REACT_APP_BASE_URL +
                "/media-api/d10_reports/" +
                formattedDate +
                "_for_kyrgyz.xlsx"
              }
            >
              <FontAwesomeIcon
                style={{ color: "green", marginRight: "10px" }}
                icon={faFileExcel}
              />{" "}
              Скачать Excel кыргызского языка
            </a>
          )}
          {isEdu_min && (
            <a
              style={{ height: "max-content", alignItems: "center" }}
              className={"btn btn-warning " + styles.download}
              href={
                process.env.REACT_APP_BASE_URL +
                "/media-api/d10_reports/" +
                formattedDate +
                "_for_russian.xlsx"
              }
            >
              <FontAwesomeIcon
                style={{ color: "green", marginRight: "10px" }}
                icon={faFileExcel}
              />{" "}
              Скачать Excel русского языка
            </a>
          )}
          {isEdu_min && (
            <a
              style={{ height: "max-content", alignItems: "center" }}
              className={"btn btn-warning " + styles.download}
              href={
                process.env.REACT_APP_BASE_URL +
                "/media-api/d10_reports/" +
                formattedDate +
                "_for_tajik.xlsx"
              }
            >
              <FontAwesomeIcon
                style={{ color: "green", marginRight: "10px" }}
                icon={faFileExcel}
              />{" "}
              Скачать Excel таджикского языка
            </a>
          )}
          {isEdu_min && (
            <a
              style={{ height: "max-content", alignItems: "center" }}
              className={"btn btn-warning " + styles.download}
              href={
                process.env.REACT_APP_BASE_URL +
                "/media-api/d10_reports/" +
                formattedDate +
                "_for_uzbek.xlsx"
              }
            >
              <FontAwesomeIcon
                style={{ color: "green", marginRight: "10px" }}
                icon={faFileExcel}
              />{" "}
              Скачать Excel узбекого языка
            </a>
          )}
        </div>
      </div>
      {(isRegObserver || isJanyKitep || isEdu_min) && (
        <p className={"mt-4"}>
          Скачайте отчет д10 нажав на кнопу выше справа, таблица в данный момент
          на доработке.
        </p>
      )}
      {(isLabrarianOrDirector || isReportResultPage) && (
        <D10Table report={report} language={language} />
      )}
      {(isDistObserver && !isReportResultPage) && (
        <D10ConfirmPdf language={language} />
      )}
      {(isLabrarianOrDirector || isDistObserver) && !forDistrict && (
        <>
          <br />
          <br />
          <h2>{t("report.d10-school.confirm-title")}</h2>
          <p style={{ color: "red", fontSize: "20px" }}>
            Скачайте сгенерированный отчет д10, распечатайте, поставьте подпись
            и печать, затем отсканируйте и прикрепите файл к соответствующему
            языку
          </p>
          {!d10Confirm && (
            <div className="mb-5 mt-4 d-flex flex-column">
              {!formKg && (
                <div>
                  <p>
                    <b>Кыргызский</b>
                  </p>
                  <input
                    type="file"
                    accept={isDistObserver ? "" : "application/pdf"}
                    placeholder="Choose a file"
                    onChange={selectedFileHandler1}
                    className="mb-2"
                  />
                </div>
              )}
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckKg"
                  onChange={hideInputKg}
                />
                <label className="form-check-label" htmlFor="flexCheckKg">
                  Отказ от подачи отчета кыргызского языка
                </label>
              </div>
              <br />
              {!formRu && (
                <div>
                  <p>
                    <b>Русский</b>
                  </p>
                  <input
                    type="file"
                    accept={isDistObserver ? "" : "application/pdf"}
                    placeholder="Choose a file"
                    onChange={selectedFileHandler2}
                    className="mb-2"
                  />
                </div>
              )}
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckRu"
                  onChange={hideInputRu}
                />
                <label className="form-check-label" htmlFor="flexCheckRu">
                  Отказ от подачи отчета русского языка
                </label>
              </div>
              <br />
              {!formTj && (
                <div>
                  <p>
                    <b>Таджикский</b>
                  </p>
                  <input
                    type="file"
                    accept={isDistObserver ? "" : "application/pdf"}
                    placeholder="Choose a file"
                    onChange={selectedFileHandler3}
                    className="mb-2"
                  />
                </div>
              )}
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckTj"
                  onChange={hideInputTj}
                />
                <label className="form-check-label" htmlFor="flexCheckTj">
                  Отказ от подачи отчета таджикского языка
                </label>
              </div>
              <br />
              {!formUz && (
                <div>
                  <p>
                    <b>Узбекский</b>
                  </p>
                  <input
                    type="file"
                    accept={isDistObserver ? "" : "application/pdf"}
                    placeholder="Choose a file"
                    onChange={selectedFileHandler4}
                    className="mb-2"
                  />
                </div>
              )}
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckUz"
                  onChange={hideInputUz}
                />
                <label className="form-check-label" htmlFor="flexCheckUz">
                  Отказ от подачи отчета узбекского языка
                </label>
              </div>
              <br />
              {!formFile && (
                <div>
                  {isDistObserver ? (
                    <>
                      <p>
                        <b>Документ с подписью и печатью</b>
                      </p>
                      <input
                        type="file"
                        accept="application/pdf"
                        placeholder="Choose a file"
                        onChange={selectedFileHandler5}
                        className="mb-2"
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              )}
              <button
                className="w-25 mt-4 btn btn-success text-white"
                onClick={selectedFileUploadHandler}
                disabled={formSubmit}
              >
                {isLoading ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm"
                      aria-hidden="true"
                    ></span>
                    <span>Загрузка...</span>
                  </>
                ) : (
                  "Загрузить отчеты Д10 и подтвердить"
                )}
              </button>
            </div>
          )}
          {d10List && (
            <TableComponent
              style={{ fontSize: "0.85rem" }}
              className="mt-5 book-fond-table"
            >
              <thead>
                <tr>
                  <th scope="col">№</th>
                  <th scope="col">{t("school-book-applications.created")}</th>
                  <th scope="col">{t("school-book-applications.year")}</th>
                  {isLabrarianOrDirector && (
                    <th scope="col">Подтвержден районом</th>
                  )}
                  {isDistObserver && (
                    <th scope="col">Подтвержден Окуу китеби</th>
                  )}
                  <th scope="col">Д10 кыргызского языка</th>
                  <th scope="col">Д10 русского языка</th>
                  <th scope="col">Д10 таджикского языка</th>
                  <th scope="col">Д10 узбекского языка</th>
                  {isDistObserver && <th scope="col">Д10 документ</th>}
                </tr>
              </thead>
              <tbody>
                {d10List?.map((obj) => (
                  <tr key={obj["id"]}>
                    <td>{obj["id"]}</td>
                    <td>{obj?.created_at?.split("T")[0]}</td>
                    <td>
                      {obj["start_year"]} - {obj["end_year"]}
                    </td>
                    <td>
                      {obj["confirmed_district"] ? "Принят" : "Не подтвержден"}
                    </td>
                    <td>
                      {obj.doc_kg && (
                        <a rel="noreferrer" target="_blank" href={obj.doc_kg}>
                          скачать
                        </a>
                      )}
                    </td>
                    <td>
                      {obj.doc_ru && (
                        <a rel="noreferrer" target="_blank" href={obj.doc_ru}>
                          скачать
                        </a>
                      )}
                    </td>
                    <td>
                      {obj.doc_tj && (
                        <a rel="noreferrer" target="_blank" href={obj.doc_tj}>
                          скачать
                        </a>
                      )}
                    </td>
                    <td>
                      {obj.doc_uz && (
                        <a rel="noreferrer" target="_blank" href={obj.doc_uz}>
                          скачать
                        </a>
                      )}
                    </td>
                    {isDistObserver && (
                      <td>
                        {obj.doc_file && (
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={obj.doc_file}
                          >
                            скачать
                          </a>
                        )}
                      </td>
                    )}
                    {/* <td>{application["comment"] === 'null'? '': application["comment"]}</td> */}
                    {/* <td><a target="_bleank" rel="noreferrer" href={application.application_doc}>скачать</a></td> */}
                  </tr>
                ))}
              </tbody>
            </TableComponent>
          )}
        </>
      )}
    </>
  );
};
